<template>
    <div class="sup_content customer-list">
        <div class="sup_page_heading">
            <h2>Website Hits</h2>
        </div>
        <div class="sup_inner_content">
            <!--- Show Website Table Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allWebsiteHitsData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allWebsiteHitsData.loading" responsiveLayout="scroll" removableSort :filters="filters" v-model:selection="allWebsiteHitsData.selectedItem" :dataKey="uuid" @row-select="selectedItem($event)" @row-select-all="selectedItem($event)">
                <template #loading>
                    Loading website data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button v-if="websiteDeactiveList" :label="allWebsiteHitsData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allWebsiteHitsData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allWebsiteHitsData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllWebsiteHits(allWebsiteHitsData.activityStatus, 'websiteStatus')" />
                            <Button v-if="allWebsiteHitsData.activityStatus !== 'active'" label="Delete All" icon="pi pi-trash" class="p-button-danger"  />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allWebsiteHitsData.search" placeholder="Search Website" @keyup.enter="fetchAllWebsiteHits(allWebsiteHitsData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allWebsiteHitsData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allWebsiteHitsData.dateRange[1] != null ? fetchAllWebsiteHits(allWebsiteHitsData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allWebsiteHitsData.search !== null || allWebsiteHitsData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllWebsiteHits(allWebsiteHitsData.activityStatus, 'resetFilters')"/>
                            <SplitButton v-if="websiteExportList" label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType"></SplitButton>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Website found.
                </template>

                <Column field="Website" header="Site">
                    <template #body="slotProps">
                        <div class="site_nameurl_list">
                        <span v-if="slotProps.data.Website !== null" class="sup_site_info">{{slotProps.data.Website.siteName}}</span> <a :href="'https://' + [slotProps.data.Website.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.Website.siteUrl}}</a>
                       </div>
                       <!-- <i class="pi pi-external-link"></i> -->
                    </template>
                </Column>

                <Column field="renewalCount" header="Renewal Count" />
                <Column field="totalHits" header="Total Hits" />
                <Column field="currentHits" header="Current Hits" />

                <Column field="status" header="Status">
                    <template #body="slotProps">
                        <span :class="['sup_item_status ', slotProps.data.status ? 'sup_item_status_success' : 'sup_item_status_danger']"><i :class="['pi ', slotProps.data.status ? 'pi-check' : 'pi-times']"></i>{{slotProps.data.status ? 'Active' : 'In-Active'}}</span>
                    </template>
                </Column>

                <Column field="createdAt" header="Created On" headerStyle="width: 12rem" :sortable="true">
                    <template #body="slotProps">
                        {{usFormatDate(slotProps.data.createdAt.split("T")[0])}} {{slotProps.data.createdAt.split("T")[1].split(".")[0]}}
                    </template>
                </Column>

                <Column field="updatedAt" header="Updated On" headerStyle="width: 12rem" :sortable="true">
                    <template #body="slotProps">
                        <!-- <span>{{slotProps.data.updatedAt}}</span> -->
                        {{usFormatDate(slotProps.data.createdAt)}}
                        <!-- {{usFormatDate(slotProps.data.updatedAt.split("T")[0])}} {{slotProps.data.updatedAt.split("T")[1].split(".")[0]}} -->
                    </template>
                </Column>
            </DataTable>
            <!--- Show Website Table End --->
        </div>
    </div>
</template>

<script>
import { websiteHits } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { formatDate, usFormatDate } from '../../../../helper/utility/utility';
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount } from 'vue';
//const FileSaver = require('file-saver');

export default {
    name: 'WebsiteHits',
    setup(){
        //Application store call
        const store = useStore();

        //Website initial data storing state
        const allWebsiteHitsData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            sortBy: 'both',
            dateRange: null,
            userId: null,
            selectedItem: null
        });

        onBeforeMount(async() => {
            fetchAllWebsiteHits();
        });

        const fetchAllWebsiteHits = async (websiteStatus,load) =>{
            let date = null;
            if(load === 'websiteStatus'){
                allWebsiteHitsData.activityStatus = websiteStatus === 'active' ? 'de-active' : 'active' ;
            }
            if(load === 'resetFilters'){
                allWebsiteHitsData.search = null;
                allWebsiteHitsData.dateRange = null;
            }

            if (load === 'dateRange') {
                date = formatDate(allWebsiteHitsData.dateRange[0]) + ' ' + formatDate(allWebsiteHitsData.dateRange[1]);
            }

            try{
                allWebsiteHitsData.loading = !allWebsiteHitsData.loading;
                const response = await axios.get( websiteHits, {
                    params: {
                        status: allWebsiteHitsData.activityStatus,
                        search: allWebsiteHitsData.search,
                        userId: allWebsiteHitsData.userId,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allWebsiteHitsData.data = response.data.data.websitehits;
                allWebsiteHitsData.total = response.data.data.total;
                allWebsiteHitsData.loading = !allWebsiteHitsData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        return{
            allWebsiteHitsData,
            fetchAllWebsiteHits,
            usFormatDate
        }
    }
}
</script>

<style>

</style>